import React, { useState, useEffect } from 'react';
import { apiHelper, toast, config, Modal, FormGroupButton, Button } from '../';
import { SolicitarCompras } from './SolicitarCompras';
import { MotivoCancelamentoForm } from './MotivoCancelamentoForm';
import { PesquisaGenerica } from '../../components/controls/PesquisaGenerica';

let setIds = new Set();
export function RequisicaoMaterialItem(props) {
    const endPoint = 'suprimentos/rm/';
    const parentId = props.parentId;
    const dataParent = props.data;
    const [data, setData] = useState([]);
    const [form, setForm] = useState([]);
    const [params, setParam] = useState({ showModal: false, new: false, edit: false });
    const [paramsProdutos, setParamProdutos] = useState({ showModal: false, new: false, edit: false });
    const [paramsMotivo, setParamMotivo] = useState({ showModal: false, new: false, edit: false });
    const [requiredStatusEntrega, setRequiredStatusEntrega] = useState([]);
    const [requiredStatusEnviado, setRequiredStatusEnviado] = useState([]);
    const [showFormFindMateriais, setShowFormFindMateriais] = useState(false);
    const [materialValue, setMaterialValue] = useState({});
    const [paramsEditarMaterial, setParamEditarMaterial] = useState({ showModal: false, new: false, edit: false });

    const fetchData = () => {
        apiHelper.get(`${endPoint}${parentId}/material/`)
            .then(res => {
                if (res.data.code === 200) {
                    setData([]);
                    setData(res.data.data);
                    props.refresh(res.data.data);
                    setIds = new Set();
                }
                else
                    toast.error('Atenção, não foi possível carregar os materiais!');
            }).catch(error => {
                toast.error(error);
            });
    }

    useEffect(fetchData, [parentId]);

    const handleNew = () => {
        setForm({});
        setMaterialValue({});
        setParam({ ...params, showModal: true, new: true, edit: false });
    }

    const handleEdit = () => {

        let materiaisGenericos = [];
        if (setIds.size === 0) {
            toast.warn('Selecione pelo menos um item');
        } else {
            setIds.forEach((id) => {
                let materialGenerico = data.find(c => c.id === id && c.nome === 'Material Genérico');
                if (materialGenerico !== undefined)
                    materiaisGenericos.push(materialGenerico);
                return true;
            });
            if (materiaisGenericos.length > 0) {
                toast.warn('Não é possível atualizar, pois existem produtos genéricos selecionados!');
                return;
            }

            setParam({ ...params, showModal: true, edit: true, new: false });
        }




    }

    const handleDelete = (id) => {
        if (!window.confirm("Deseja realmente excluir este registro ?"))
            return;

        apiHelper.api_delete(`${endPoint}item/${id}`)
            .then(res => {
                if (res.data.code === 200) {
                    config.httpResult(res.data);
                    fetchData();
                }
                else
                    toast.error('Atenção, não foi possível excluir o produto!');
            }).catch(error => {
                toast.error('Atenção, não foi possível excluir o produto!');
            });
    }

    const handleShowModalEditarMaterial = (id) => {
        setMaterialValue({});
        setParamEditarMaterial({ ...paramsEditarMaterial, showModal: true, edit: true, new: false, id: id });
    }
    const handleSolicitarCompra = () => {
        if (setIds.size === 0) {
            toast.warn('Selecione pelo menos um item');
            return;
        }
        setParamProdutos({ ...paramsProdutos, showModal: true, new: true, edit: false });
    }

    const handleChange = (e) => {
        setRequiredStatusEntrega(false);
        setRequiredStatusEnviado(false);

        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        if (name === 'status' && value === 'Entregue') {
            setRequiredStatusEntrega(true);
        }
        else if (name === 'status' && value === 'Enviado') {
            setRequiredStatusEnviado(true);
        }

        setForm({ ...form, [name]: value });
    }

    const handleChangeEdit = (id) => {

        if (id) {
            if (setIds.has(id))
                setIds.delete(id);
            else
                setIds.add(id);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        var obj = form;
        obj.requisicaoId = parentId;
        obj.siteId = dataParent.siteId;
        obj.materialId = materialValue.id
        obj.processed = 0;

        if (params.new) {
            obj.status = "Rascunho";

            apiHelper.post(endPoint + 'item', obj).then(res => {
                if (res.data.code === 200) {
                    config.httpResult(res.data);
                    setParam({ ...params, showModal: false, edit: false, new: false });
                    fetchData();
                } else
                    toast.error('Atenção, não foi possível salvar a requisição.');
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar a requisição.');
            });
        }
        else if (params.edit) {
            obj.ids = JSON.stringify([...setIds]);

            apiHelper.put(endPoint, obj).then(res => {
                if (res.data.code === 200) {
                    config.httpResult(res.data);
                    setParam({ ...params, showModal: false, edit: false, new: false });
                    fetchData();
                    setMaterialValue({});
                } else if (res.data.code === 406) {
                    toast.warn(res.data.msg);
                }
                else
                    toast.error('Atenção, não foi possível salvar a requisição.');
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar a requisição.');
            });
        }
    }

    const handleHide = () => {
        setParam({ ...params, showModal: false, edit: false, new: false });
        fetchData();
    }

    const handleHideModalProdutos = () => {
        setParamProdutos({ ...paramsProdutos, showModal: false, edit: false, new: false });
        fetchData();
    }

    const handleHideModalMotivo = () => {
        setParamMotivo({ ...paramsMotivo, showModal: false, edit: false, new: false });
        fetchData();
    }

    const handleHideModalEditarMaterial = () => {
        setParamEditarMaterial({ ...paramsEditarMaterial, showModal: false, edit: false, new: false, id: 0 });
        fetchData();
    }

    const handleCancelarRequisicao = () => {
        setParamMotivo({ ...paramsMotivo, showModal: true, new: true, edit: false });
    }

    const handleAddMaterial = (row) => {
        setMaterialValue({ ...materialValue, nome: row.nome, id: row.pkMaterial });
    }

    const handleEditarMaterial = () => {

        let obj = materialValue;
        apiHelper.put(`${endPoint}/material/${paramsEditarMaterial.id}`, obj).then(res => {
            if (res.data.code === 200) {
                config.httpResult(res.data);
                setMaterialValue({});
                handleHideModalEditarMaterial();
            } else if (res.data.code === 406) {
                toast.warn(res.data.msg);
            }
            else
                toast.error('Atenção, não foi possível salvar a requisição.');
        }).catch(error => {
            toast.error('Atenção, não foi possível salvar a requisição.');
        });
    }

    return (
        <React.Fragment>
            <div className="box box-primary">
                <div className="box-header with-border">
                    <h3 className="box-title">Materiais</h3>
                    <div className="box-tools pull-right">
                        <div className="has-feedback">
                        </div>
                    </div>
                </div>
                <div className="box-body">
                    <div style={{ marginBottom: "7px" }}>
                        {(dataParent.solicitanteId === config.idUsuarioLogado() && dataParent.status === "Rascunho") && <button type="button" style={{ marginRight: "7px" }} className="btn btn-default btn-sm btn-flat" onClick={handleNew}><i className="fa fa-plus" /> Adicionar</button>}
                        {(config.validarClaim("RequisicaoMaterialEstoque") || config.validarClaim("RequisicaoMaterialCompra")) && (dataParent.status !== "Rascunho" && dataParent.status !== "Em Aprovação" && dataParent.status !== "Concluido" && dataParent.status !== "Cancelado") && <button type="button" style={{ marginRight: "7px" }} className="btn btn-default btn-sm btn-flat" onClick={handleEdit}><i className="fa fa-edit" /> Atualizar</button>}
                        {config.validarClaim("RequisicaoMaterialEstoque") && (dataParent.status !== "Rascunho" && dataParent.status !== "Concluido" && dataParent.status !== "Cancelado" && dataParent.status !== "Em Aprovação") && <button type="button" style={{ marginRight: "7px" }} className="btn btn-default btn-sm btn-flat" onClick={handleSolicitarCompra}><i className="fa fa-shopping-cart" /> Solicitar Compra</button>}
                        {config.validarClaim("RequisicaoMaterialEstoque") && (dataParent.status !== "Rascunho" && dataParent.status !== "Concluido" && dataParent.status !== "Cancelado" && dataParent.status !== "Em Aprovação") && <button type="button" style={{ marginRight: "7px" }} className="btn btn-default btn-sm btn-flat" onClick={handleCancelarRequisicao}><i className="fa fa-ban" /> Cancelar Requisição</button>}

                        <div className="pull-right">
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                            <thead>
                                <tr className="bg-light-blue">
                                    <th>#</th>
                                    {(config.validarClaim("RequisicaoMaterialEstoque") || config.validarClaim("RequisicaoMaterialCompra")) && (dataParent.status === "Em Aprovação") && data.find(c => c.nome === 'Material Genérico') && <th>Editar Material</th>}
                                    <th>Protheus</th>
                                    <th>Qtde</th>
                                    <th>Produto</th>
                                    <th>Site</th>
                                    <th>Valor</th>
                                    <th>Status</th>
                                    <th>Quantidade Compra</th>
                                    <th>Observação</th>
                                    <th>SugestãoFornecedor</th>
                                    <th>Comprar</th>
                                    <th>Montagem</th>
                                    <th>Detalhamento Técnico</th>
                                    <th>Previsão Entrega</th>
                                    <th>Data Entrega</th>
                                    <th>Cód. Obra</th>
                                    {(dataParent.status === 'Rascunho' && data.find(w => w.status === 'Rascunho')) && <th>Excluir</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>
                                                {item.status === 'Entregue' ? <input type="checkbox" id="ids" checked={true} disabled={true} /> :
                                                    <input type="checkbox" id="ids" onClick={() => handleChangeEdit(item.id)} />}
                                            </td>
                                            {(config.validarClaim("RequisicaoMaterialEstoque") || config.validarClaim("RequisicaoMaterialCompra")) && (dataParent.status === "Em Aprovação") && (item.nome === 'Material Genérico') && <td align="center"><span> <i className="fa fa-pencil-square-o" onClick={() => handleShowModalEditarMaterial(item.id)} /></span></td>}
                                            <td>{item.codigoProtheus}</td>
                                            <td>{item.quantidade}</td>
                                            <td>{item.nome}</td>
                                            <td>{item.site}</td>
                                            <td>{config.currencyFix(item.valor)}</td>
                                            <td>{item.status}</td>
                                            <td>{item.quantidadeCompra}</td>
                                            <td>{item.observacao}</td>
                                            <td>{item.sugestaoFornecedor}</td>
                                            <td>{item.comprar && item.comprar === true ? 'Sim' : 'Não'}</td>
                                            <td>{item.montagem && item.montagem === true ? 'Sim' : 'Não'}</td>
                                            <td>{item.detalhamentoTecnico}</td>
                                            <td>{config.dateFix(item.previsaoEntrega)}</td>
                                            <td>{config.dateFix(item.dataEntrega)}</td>
                                            <td>{dataParent.origem === 'Usuario' ? dataParent.codigoObra : (1000000 + item.siteId)}</td>
                                            {(dataParent.status === 'Rascunho' && item.status === "Rascunho") && <td align="center"><span> <i className="fa fa-trash" onClick={() => handleDelete(item.id)} /></span></td>}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{params.edit ? "Atualizar" : "Adicionar Produto"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        {params.edit &&
                            <React.Fragment>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="name">Status</label>
                                        <select id="status" className="form-control" onChange={handleChange} value={form.status || ""} required>
                                            <option value="">Selecione</option>
                                            {config.validarClaim("RequisicaoMaterialCompra") && <option value="Compra Finalizada">Compra Finalizada</option>}
                                            {config.validarClaim("RequisicaoMaterialEstoque") && <option value="Separado">Separado</option>}
                                            {config.validarClaim("RequisicaoMaterialEstoque") && <option value="Enviado">Enviado</option>}
                                            {config.validarClaim("RequisicaoMaterialEstoque") && <option value="Entregue">Entregue</option>}
                                        </select>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>Data Necessidade</label>
                                        <input type="text" id="dataNecessidade" className="form-control" value={config.dateFix(dataParent.dataNecessidade)} disabled />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label>Previsão Entrega</label>
                                        <input type="date" id="previsaoEntrega" className="form-control" required={requiredStatusEnviado} onChange={handleChange} value={form.previsaoEntrega} />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>Data Entrega</label>
                                        <input type="date" id="dataEntrega" className="form-control" required={requiredStatusEntrega} onChange={handleChange} value={form.dataEntrega} />
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                        {params.new &&
                            <div className="row">
                                <div className="form-group col-md-9">
                                    <FormGroupButton placeholder="Material" label="Material" disabled
                                        value={materialValue.nome}
                                        buttonRight={<Button bsStyle="success" type="button" onClick={() => setShowFormFindMateriais(true)}>...</Button>}
                                    />
                                </div>
                                <div className="form-group col-md-3">
                                    <label>Quantidade</label>
                                    <input type="number" id="quantidade" className="form-control" onChange={handleChange} value={form.quantidade || ''} required />
                                </div>
                                <div className="form-group col-md-12">
                                    <label>Sugestão Fornecedor</label>
                                    <input type="text" id="sugestaoFornecedor" className="form-control" onChange={handleChange} value={form.sugestaoFornecedor || ''} required />
                                </div>
                                <div className="form-group col-md-12">
                                    <label>Observação</label>
                                    <textarea id="observacao" className="form-control" rows={3} onChange={handleChange} value={form.observacao || ''} required={materialValue.nome === "Material Genérico" ? true : false} />
                                </div>
                            </div>
                        }
                        <div className="row">
                            <div className="col-md-12">
                                <button type="button" className="btn btn-default pull-right" onClick={handleHide}>Cancelar</button>
                                <button type="submit" className="btn btn-primary pull-right" style={{ marginRight: "7px" }}>{params.edit ? "Atualizar" : "Adicionar"}</button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
            <Modal show={paramsProdutos.showModal} onHide={handleHideModalProdutos} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Quantidade de produtos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {paramsProdutos.new === true && <SolicitarCompras setIds={setIds} {...props} onHide={handleHideModalProdutos} />}
                </Modal.Body>
            </Modal>
            <Modal show={paramsMotivo.showModal} onHide={handleHideModalMotivo} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Motivo Cancelamento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {paramsMotivo.new === true && <MotivoCancelamentoForm {...props} onHide={handleHideModalMotivo} />}
                </Modal.Body>
            </Modal>
            <Modal show={paramsEditarMaterial.showModal} onHide={handleHideModalEditarMaterial} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Editar Material</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="form-group col-md-12">
                            <FormGroupButton placeholder="Material" label="Material" disabled
                                value={materialValue.nome}
                                buttonRight={<Button bsStyle="success" type="button" onClick={() => setShowFormFindMateriais(true)}>...</Button>}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <button type="button" className="btn btn-default pull-right" onClick={() => handleHideModalEditarMaterial()}>Fechar</button>
                            <button type="submit" className="btn btn-primary pull-right" onClick={() => handleEditarMaterial()} style={{ marginRight: "7px" }}>Salvar</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {showFormFindMateriais && <PesquisaGenerica config={{ apiUrl: localStorage.getItem('apiUrl') }} show={showFormFindMateriais} title="Buscar Materiais" source={`${endPoint}material?filter=`} onImport={(row) => { handleAddMaterial(row) }} onHide={() => { setShowFormFindMateriais(false) }} />}
        </React.Fragment>
    )
}
